<template>
  <form @submit.prevent="addToken">
    <modal-card
      :title="isEdit ? 'Update access token' : `Generate access token`"
      :processing="processing"
    >
      <section>
        <b-message type="is-dark">
          <p>
            Here you can generate an access token for use with our internal API
            (advanced users only). The token must be passed using a "Bearer"
            authorization header.
          </p>
        </b-message>

        <b-field>
          <b-switch
            v-model="expires"
            :disabled="processing"
            @input="onExpiresChanged"
            >Add token expiry date</b-switch
          >
        </b-field>

        <b-field v-if="isEdit">
          <b-switch v-model="form.isActive" :disabled="processing"
            >Active</b-switch
          >
        </b-field>

        <b-field v-if="expires" label="Expiry date">
          <schedule-picker
            v-model="dateExpires"
            allow-only-date
            :disabled="processing"
          />
        </b-field>
      </section>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm || loading || processing"
        type="submit"
        class="button is-success"
      >
        {{ isEdit ? "Update" : "Generate" }}
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "TokenModal",
  props: {
    token: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      expires: this.$_.get(this.token, "_id")
        ? Boolean(this.$_.get(this.token, "dateExpires"))
        : false,
      form: {
        dateExpires: null,
        isActive: true,
        type: "user"
      }
    };
  },
  computed: {
    isEdit() {
      return !!this.$_.get(this.token, "_id");
    },
    validForm() {
      if (this.expires && !this.dateExpires) return false;
      return true;
    },
    dateExpires: {
      get() {
        return this.$_.isObject(this.form.dateExpires) &&
          !this.$_.isDate(this.form.dateExpires)
          ? this.form.dateExpires.toDate()
          : this.form.dateExpires;
      },
      set(value) {
        this.form.dateExpires = value;
      }
    }
  },
  created() {
    this.form = {
      ...this.form,
      ...this.token
    };
  },
  methods: {
    onExpiresChanged(expires) {
      if (expires) this.$set(this.form, "dateExpires", null);
      else this.$delete(this.form, "dateExpires");
    },
    addToken() {
      if (!this.validForm) return;
      this.processing = true;
      this.$store
        .dispatch("tokens/addToken", {
          ...this.form,
          dateExpires: this.form.dateExpires
            ? this.$moment(this.form.dateExpires).format("DD/MM/YY HH:mm")
            : null
        })
        .then(() => {
          this.processing = false;
          this.$emit("close");
          this.$toast.open({
            message: this.isEdit ? `Token updated` : `Token created`
          });
        })
        .catch(error => {
          this.processing = false;
          this.$emit("close");
          this.$toast.open({
            message: `Error saving token: ${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
